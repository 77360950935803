import { Control, Pointer } from '@yleisradio/areena-types';
import CirclePlayIcon from 'assets/programControlPlayIcon.svg';
import classNames from 'classnames';
import { isNavigator } from 'components/Controls/Control/typeGuards';
import { useControl } from 'components/Controls/Control/useControl';
import styles from 'components/Guide/Program/Program.module.scss';
import { Link } from 'components/Link';
import { PropsWithChildren } from 'react';

interface ControlProps {
  disabled?: boolean | undefined;
  control: Control;
}

type ControlElementProps = PropsWithChildren<{
  pointer: Pointer;
  onClick: (() => void) | undefined;
  className: string;
}>;

const ControlElement = ({
  pointer,
  onClick,
  className,
  children,
}: ControlElementProps) => {
  return (
    <Link pointer={pointer} className={className} onClick={onClick}>
      {children}
    </Link>
  );
};

export const ProgramControl = ({
  disabled = false,
  control: programControl,
}: ControlProps) => {
  const { control, action } = useControl({ control: programControl });

  return isNavigator(control) ? (
    <ControlElement
      pointer={control.destination}
      onClick={action}
      className={classNames(
        styles.controlLink,
        disabled && styles.controlDisabled
      )}
    >
      <CirclePlayIcon
        className={classNames(
          styles.controlLinkPlayButton,
          disabled && styles.controlLinkPlayButtonDisabled
        )}
      />
      <span>{control.title}</span>
    </ControlElement>
  ) : null;
};

export const DisabledProgramControl = ({ label }: { label: string }) => {
  return (
    <div className={classNames(styles.controlLink, styles.controlDisabled)}>
      <CirclePlayIcon
        aria-hidden
        className={classNames(
          styles.controlLinkPlayButton,
          styles.controlLinkPlayButtonDisabled
        )}
      />
      <span>{label}</span>
    </div>
  );
};
