export function debounce<A extends unknown[]>(
  fn: (...args: A) => void,
  timeoutInMs = 500
): (...args: A) => void {
  let timeoutId: ReturnType<typeof setTimeout>;

  return function (...args) {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      fn(...args);
    }, timeoutInMs);
  };
}
