import * as Progress from '@radix-ui/react-progress';
import styles from '../Program.module.scss';

function getProgress(start: number, end: number, current: number): number {
  if (start === end) return 0;

  const elapsed = Math.max(0, current - start);
  const duration = end - start;

  const result = Math.round((elapsed / duration) * 100);

  return Math.min(100, Math.max(0, result));
}

type Props = {
  start: number;
  end: number;
  current: number;
};

const ProgressBar = ({ start, end, current }: Props) => {
  const currentProgress = getProgress(start, end, current);

  return !Number.isNaN(currentProgress) ? (
    <Progress.Root
      className={styles.progressRoot}
      value={currentProgress}
      title={`${currentProgress}%`}
    >
      <Progress.Indicator
        className={styles.progressIndicator}
        style={{
          width: `${currentProgress}%`,
        }}
      />
    </Progress.Root>
  ) : null;
};

export default ProgressBar;
