import {
  Language,
  Service,
  View as ViewData,
  ViewMeta,
} from '@yleisradio/areena-types';
import { Guide } from 'components/Guide';
import { GuideMetadata } from 'components/Metadata';
import { AnalyticsContextProvider } from 'contexts/AnalyticsContext';
import { useAreenaService } from 'contexts/AreenaServiceContext';
import { ReferrerProvider } from 'contexts/ReferrerContext';
import apm from 'elastic-apm-node';
import { useIsomorphicLayoutEffect } from 'hooks/useIsomorphicLayoutEffect';
import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import { FunctionComponent, useMemo } from 'react';
import { getView } from 'services/areena-api/fetchers';
import { getGuideViewPath } from 'services/areena-api/path';
import { filterViewByAudience } from 'utils/content';
import {
  getEpgDateBoundaries,
  getTodaysEpgDate,
  isEpgDateInFuture,
  isValidEpgDate,
} from 'utils/epg-date/backend';
import { isAuthenticated } from 'utils/login';
import { getCanonicalGuidePath } from 'utils/url';

type Props = {
  areenaService: Service;
  epgDate: string;
  firstDateOfEpg: string;
  isEpgOfFutureDate: boolean;
  isEpgOfToday: boolean;
  lastDateOfEpg: string;
  meta: ViewMeta;
  path: string;
  referrer: string | null;
  userIsAuthenticated: boolean;
  view: ViewData;
};

// This same component is used in all TV and radio guide pages
const Page: FunctionComponent<Props> = ({
  areenaService,
  epgDate,
  firstDateOfEpg,
  isEpgOfFutureDate,
  isEpgOfToday,
  lastDateOfEpg,
  meta,
  path,
  referrer,
  userIsAuthenticated,
  view,
}) => {
  const { query } = useRouter();

  const filteredViewData = useMemo(
    () => filterViewByAudience(view, userIsAuthenticated),
    [view, userIsAuthenticated]
  );

  const { setAreenaService } = useAreenaService();

  useIsomorphicLayoutEffect(() => {
    setAreenaService(areenaService ?? undefined);
  }, [areenaService, setAreenaService]);

  return (
    <>
      <GuideMetadata
        path={path}
        query={query}
        view={view}
        viewMeta={meta}
        guideDate={epgDate}
        isTodaysGuide={isEpgOfToday}
      />
      <ReferrerProvider referrer={referrer || undefined}>
        <AnalyticsContextProvider context={view.analytics?.context?.comscore}>
          <Guide
            currentDate={epgDate}
            firstDate={firstDateOfEpg}
            lastDate={lastDateOfEpg}
            isFutureDate={isEpgOfFutureDate}
            isToday={isEpgOfToday}
            view={filteredViewData}
          />
        </AnalyticsContextProvider>
      </ReferrerProvider>
    </>
  );
};

export default Page;

// This same function is used in all TV and radio guide pages
export const getServerSideProps: GetServerSideProps<Props> = async ({
  locale,
  req,
  res,
  resolvedUrl,
}) => {
  const {
    cookies,
    headers: { cookie: cookieHeader, referer: referrer = null },
  } = req;

  const { pathname: path, searchParams: queryParams } = new URL(
    resolvedUrl,
    'https://localhost/'
  );

  apm.setTransactionName(`GET ${path}`);

  const language: Language = locale === 'sv' ? 'sv' : 'fi';
  const service: Service = path.startsWith('/tv') ? 'tv' : 'radio';

  const userSuppliedEpgDate = queryParams.get('t');
  const todaysEpgDate = getTodaysEpgDate();
  const epgDate = getEpgDate(userSuppliedEpgDate, todaysEpgDate);
  const isEpgOfToday = epgDate === todaysEpgDate;

  if (!epgDate) {
    return { notFound: true };
  }

  const canonicalPath = getCanonicalGuidePath(service, language);

  if (path !== canonicalPath) {
    return {
      redirect: {
        destination: userSuppliedEpgDate
          ? `${canonicalPath}?t=${epgDate}`
          : canonicalPath,
        permanent: true,
      },
    };
  }

  const apiResponse = await getView({
    path: getGuideViewPath(epgDate, service),
    language,
    requestCookie: cookieHeader,
  });

  if (apiResponse) {
    const { setCookieHeaders, meta, view } = apiResponse;

    res.setHeader('Cache-Control', 'max-age=3600');

    if (setCookieHeaders) {
      res.setHeader('Set-Cookie', setCookieHeaders);
    }

    const epgDateBoundaries = getEpgDateBoundaries(todaysEpgDate);

    return {
      props: {
        view,
        meta,
        path,
        userIsAuthenticated: isAuthenticated(cookies),
        epgDate,
        firstDateOfEpg: epgDateBoundaries.first,
        lastDateOfEpg: epgDateBoundaries.last,
        isEpgOfFutureDate: isEpgDateInFuture(epgDate, todaysEpgDate),
        isEpgOfToday,
        referrer,
        areenaService: service,
      } satisfies Props,
    };
  }

  return { notFound: true };
};

/**
 * @returns valid EPG date or null if the user-supplied EPG date is invalid.
 */
function getEpgDate(
  userSuppliedEpgDate: string | null,
  todaysEpgDate: string
): string | null {
  if (userSuppliedEpgDate == null) {
    return todaysEpgDate;
  }

  if (isValidEpgDate(userSuppliedEpgDate, todaysEpgDate)) {
    return userSuppliedEpgDate;
  }

  return null;
}
