import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import React, { useId } from 'react';
import styles from './GuideHeader.module.scss';

type Props = {
  title: string | undefined;
  datePicker: React.ReactElement;
  pastProgramsToggle: React.ReactElement | null;
};

export const GuideHeader: React.FunctionComponent<Props> = (props) => {
  const headingId = useId();

  return (
    <nav className={styles.root} aria-labelledby={headingId}>
      <VisuallyHidden.Root asChild>
        <h1 id={headingId}>{props.title}</h1>
      </VisuallyHidden.Root>

      <div className={styles.pickerWrapper}>{props.datePicker}</div>
      {props.pastProgramsToggle ? (
        <div className={styles.toggleWrapper}>{props.pastProgramsToggle}</div>
      ) : null}
    </nav>
  );
};
