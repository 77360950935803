import React from 'react';
import Play from 'assets/playIconRound.svg';
import classNames from 'classnames';
import styles from '../Program.module.scss';

interface PlayIconProps {
  active?: boolean | undefined;
  'aria-label': string;
}

export const PlayIcon = (props: PlayIconProps) => {
  return (
    <Play
      aria-label={props['aria-label']}
      className={classNames(
        styles.playIcon,
        props.active && styles.activePlayIcon
      )}
    />
  );
};
