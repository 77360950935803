import { DSNotification } from 'components/DSNotification';
import React from 'react';
import styles from './ExternalChannelsNotice.module.scss';
import { useTranslation } from 'hooks/useTranslation';

export const ExternalChannelsNotice: React.FC = () => {
  const t = useTranslation();

  return (
    <div className={styles.root}>
      <DSNotification
        type="info"
        state="primary"
        title={t('externalChannelsMissingNoticeTitle')}
        subtitle={t('externalChannelsMissingNoticeDescription')}
      />
    </div>
  );
};
