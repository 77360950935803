import { Tab } from '@yleisradio/areena-types';
import { ChannelHeader } from 'components/Guide/ChannelHeader/ChannelHeader';
import { ChannelSelectionMenu } from 'components/Guide/ChannelSelectionMenu/ChannelSelectionMenu';
import { isListArray } from 'utils/content';
import { Channel } from './Channel';
import { useCookie } from 'hooks/useCookie';

interface SelectableChannelProps {
  channel: Tab;
  isFutureDate: boolean;
  isToday: boolean;
  showExpired: boolean;
}

export const SelectableChannel = (props: SelectableChannelProps) => {
  const { persistentState, image, title = '' } = props.channel;

  const channelContent = isListArray(props.channel.content)
    ? props.channel.content
    : [];

  const [storedRegionalChannel, setStoredRegionalChannel] = useCookie(
    persistentState?.key && `region-${persistentState.key}`
  );

  const selectedChannel = channelContent.find(
    (c) => c.persistentState?.id === storedRegionalChannel
  );

  const handleChannelSelect = (channelId: string) => {
    setStoredRegionalChannel(channelId);
  };

  const channelControl = selectedChannel?.controls?.find(
    (c) => c.destination?.type === 'service'
  );

  const currentChannelContent = selectedChannel || channelContent[0];

  return currentChannelContent ? (
    <Channel
      isToday={props.isToday}
      image={props.channel.image}
      showExpired={props.showExpired}
      channelContent={currentChannelContent}
      title={currentChannelContent.title}
      isFutureDate={props.isFutureDate}
      channelHeader={(headingId) => (
        <ChannelHeader
          image={channelControl?.image || image}
          title={title}
          headingId={headingId}
        >
          <ChannelSelectionMenu
            onSelect={handleChannelSelect}
            selectedChannel={storedRegionalChannel || undefined}
            channels={channelContent}
          />
        </ChannelHeader>
      )}
    />
  ) : null;
};
