import { useTranslation } from 'hooks/useTranslation';
import styles from './PastProgramsToggle.module.scss';

type Props = {
  checked: boolean;
  toggle: () => void;
};

export const PastProgramsToggle: React.FunctionComponent<Props> = ({
  checked,
  toggle,
}) => {
  const t = useTranslation();
  return (
    <label className={styles.label}>
      <input type="checkbox" checked={checked} onChange={toggle} />
      <span className={styles.text}>{t('showPastPrograms')}</span>
    </label>
  );
};
