import { Image, List } from '@yleisradio/areena-types';
import { Button } from 'components/Button';
import { useCards } from 'hooks/useCards';
import { useTranslation } from 'hooks/useTranslation';
import React, { useEffect, useId, useState } from 'react';
import { ChannelHeader } from '../ChannelHeader/ChannelHeader';
import { Program } from '../Program/Program';
import { ProgramList, ProgramListItem } from '../ProgramList';
import { createSchedule } from '../utils';
import styles from './Channel.module.scss';
import { AnalyticsContextProvider } from 'contexts/AnalyticsContext';

interface ChannelProps {
  isFutureDate: boolean;
  isToday: boolean;
  channelContent: List;
  title: string | undefined;
  image: Image | undefined;
  channelHeader?: (headingID: string) => React.ReactNode;
  showExpired: boolean;
}

const DEFAULT_SHOWN_PROGRAMS = 15;

export const Channel = ({
  isFutureDate,
  isToday,
  channelContent,
  title = '',
  image,
  channelHeader,
  showExpired,
}: ChannelProps) => {
  const { cards, loading, errored, analyticsContext } = useCards({
    source: channelContent.source,
    pageIndex: 0,
    pageSize: 100,
  });

  const [showAllUpcoming, setShowAllUpcoming] = useState(false);
  const [currentTime, setCurrentTime] = useState(Date.now());
  const { expired, current, upcoming } = createSchedule(cards, currentTime);

  const headingId = useId();
  const t = useTranslation();

  const programs = [
    ...(showExpired || !isToday ? expired : []),
    ...current,
    ...(showAllUpcoming || !isToday
      ? upcoming
      : upcoming.slice(0, DEFAULT_SHOWN_PROGRAMS - current.length)),
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  const getErrorMessage = (): string => {
    if (isFutureDate) return t('noScheduleAvailableYet');

    if (isToday && cards.length > 0) return t('endOfSchedule');

    return t('noTransmissions');
  };

  return (
    <section className={styles.channelRoot} aria-labelledby={headingId}>
      {channelHeader ? (
        channelHeader(headingId)
      ) : (
        <ChannelHeader headingId={headingId} image={image} title={title} />
      )}

      <div className={styles.channelProgramsRoot}>
        {!loading ? (
          <AnalyticsContextProvider context={analyticsContext}>
            <ProgramList>
              {programs.length > 0 ? (
                programs.map((prog) => (
                  <ProgramListItem key={prog.key}>
                    <Program currentTime={currentTime} program={prog} />
                  </ProgramListItem>
                ))
              ) : (
                <div className={styles.message}>{getErrorMessage()}</div>
              )}
            </ProgramList>

            {isToday &&
              current.length + upcoming.length > DEFAULT_SHOWN_PROGRAMS && (
                <span className={styles.showMoreButtonContainer}>
                  <Button
                    size="xs"
                    variant="secondary"
                    onClick={() => setShowAllUpcoming((state) => !state)}
                    text={
                      showAllUpcoming ? t('showLessGuide') : t('showMoreGuide')
                    }
                  />
                </span>
              )}
          </AnalyticsContextProvider>
        ) : null}
        {errored && (
          <div className={styles.message}>{t('channelErrorMessage')}</div>
        )}
      </div>
    </section>
  );
};
