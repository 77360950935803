import { Image } from '@yleisradio/areena-types';
import React from 'react';
import { ChannelImage } from '../ChannelImage/ChannelImage';
import styles from './ChannelHeader.module.scss';

interface ChannelHeaderProps {
  image: Image | undefined;
  title: string;
  children?: React.ReactNode | React.ReactNode[];
  headingId: string | undefined;
}

export const ChannelHeader = ({
  image,
  title,
  children,
  headingId,
}: ChannelHeaderProps) => {
  return (
    <header className={styles.channelHeaderRoot}>
      <h2 id={headingId} className={styles.channelHeaderH2}>
        {image ? <ChannelImage image={image} alt={title} /> : title}
      </h2>
      {children}
    </header>
  );
};
