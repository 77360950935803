import React from 'react';
import styles from './ProgramList.module.scss';

type Props = {
  children: React.ReactNode;
};

export const ProgramList: React.FC<Props> = ({ children }) => {
  return <ul className={styles.root}>{children}</ul>;
};
