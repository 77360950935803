import React from 'react';
import styles from './ChannelSelectionMenu.module.scss';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import ChevronDown from 'assets/chevronDown.svg';
import { List } from '@yleisradio/areena-types';
import { useTranslation } from 'hooks/useTranslation';
import classNames from 'classnames';

interface ChannelSelectionMenuProps {
  onSelect: (selection: string) => void;
  selectedChannel?: string | undefined;
  channels: List[];
}

export const ChannelSelectionMenu = (props: ChannelSelectionMenuProps) => {
  const t = useTranslation();

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger
        className={styles.button}
        aria-label={t('selectRegionalChannel')}
      >
        <ChevronDown className={styles.chevronDownIcon} aria-hidden="true" />
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content className={styles.menu} align="end">
          {props.channels.map((channel) => (
            <DropdownMenu.Item
              key={channel.title}
              className={styles.menuItem}
              onSelect={() => {
                if (channel.persistentState?.id) {
                  props.onSelect(channel.persistentState.id);
                } else {
                  throw new Error(
                    "Regional channel doesn't have persistentState.id"
                  );
                }
              }}
              data-highlighted={false}
            >
              <span
                className={classNames(
                  styles.menuItemText,
                  channel.persistentState?.id === props.selectedChannel
                    ? styles.selectedChannel
                    : null
                )}
              >
                {channel.title || ''}
              </span>
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
