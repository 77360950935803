import { Card } from '@yleisradio/areena-types';
import { findLabel } from 'utils/card';

type BroadcastStatus = 'expired' | 'current' | 'upcoming';

export const determineBroadcastStatus = (
  card: Card,
  time: number
): BroadcastStatus => {
  const startDateLabel = findLabel(card.labels, 'broadcastStartDate')?.raw;
  const endDateLabel = findLabel(card.labels, 'broadcastEndDate')?.raw;

  if (!startDateLabel) throw new Error('Missing broadcastStartDate label');
  if (!endDateLabel) throw new Error('Missing broadcastEndDate label');

  const start = Date.parse(startDateLabel);
  const end = Date.parse(endDateLabel);

  if (isNaN(start)) throw new Error('Invalid broadcastStartDate label');
  if (isNaN(end)) throw new Error('Invalid broadcastEndDate label');

  if (time > end) return 'expired';
  if (time < start) return 'upcoming';
  return 'current';
};

interface CardWithKey extends Card {
  key: string | number;
}

export function createSchedule(
  schedule: Card[],
  time: number
): Record<BroadcastStatus, CardWithKey[]> {
  const cardsWithKeys = schedule.map<CardWithKey>((card, i) => ({
    ...card,
    key: i,
  }));

  const getByStatus = (status: BroadcastStatus) =>
    cardsWithKeys.filter(
      (cardWithKey) => determineBroadcastStatus(cardWithKey, time) === status
    );

  return {
    expired: getByStatus('expired'),
    current: getByStatus('current'),
    upcoming: getByStatus('upcoming'),
  };
}
