import { Image as ImageType } from '@yleisradio/areena-types';
import Image from 'next/image';
import { guideChannelImageLoader, loaderUrl } from 'utils/cloudinary';
import React from 'react';

interface ChannelImageProps {
  image: ImageType;
  alt: string | undefined;
}

export const ChannelImage: React.FunctionComponent<ChannelImageProps> = ({
  image,
  alt = '',
}) => {
  return (
    <Image
      style={{ display: 'block' }}
      key={image.id}
      alt={alt}
      height={32}
      width={150}
      loader={guideChannelImageLoader}
      src={loaderUrl(image)}
    />
  );
};
