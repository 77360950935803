import { useLocalStorage } from 'hooks/useLocalStorage';
import logger from 'services/logger';

export function useShowPastPrograms(): [boolean, (value: boolean) => void] {
  const [state, setState] = useLocalStorage<boolean | 0 | 1>(
    'guideShowPastPrograms',
    false
  );

  if (typeof state !== 'boolean' && typeof state !== 'number') {
    logger.warn(
      `Incompatible local storage value (${typeof state}) for show past programs`
    );
  }

  return [!!state, setState];
}
