import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import ChevronLeft from 'assets/chevronLeft.svg';
import ChevronRight from 'assets/chevronRight.svg';
import { useTranslation } from 'hooks/useTranslation';
import { useUILanguage } from 'hooks/useUILanguage';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useId, useMemo, useRef, useState } from 'react';
import logger from 'services/logger';
import { debounce } from 'utils/debounce';
import { formatEpgDate, getSurroundingEpgDates } from 'utils/epg-date/frontend';
import styles from './DatePicker.module.scss';
import { prettyPath } from 'utils/url';

type Props = {
  /** Selected EPG date (YYYY-MM-DD) */
  currentDate: string;
  /** First available EPG date (YYYY-MM-DD) */
  firstDate: string;
  /** Last available EPG date (YYYY-MM-DD) */
  lastDate: string;
};

export const DatePicker: React.FunctionComponent<Props> = ({
  currentDate,
  firstDate,
  lastDate,
}) => {
  const language = useUILanguage();
  const router = useRouter();
  const t = useTranslation();

  const inputId = useId();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isInputVisible, setInputVisibility] = useState(false);

  const changeDate = useMemo(
    () =>
      debounce((date: string) => {
        const updatedQuery = { ...router.query, t: date };

        void router.replace(
          {
            pathname: router.pathname,
            query: updatedQuery,
          },
          prettyPath(router.asPath, updatedQuery)
        );
      }),
    [router]
  );

  useEffect(() => {
    if (inputRef.current) inputRef.current.value = currentDate;
  }, [currentDate]);

  useEffect(() => {
    if (isInputVisible) {
      inputRef.current?.focus();

      try {
        inputRef.current?.showPicker();
      } catch (e) {
        logger.error(e, "Couldn't show date picker");
      }
    }
  }, [isInputVisible]);

  const { previous: previousDate, next: nextDate } =
    getSurroundingEpgDates(currentDate);

  return (
    <div className={styles.root}>
      {currentDate !== firstDate ? (
        <Link
          className={styles.link}
          href={{
            pathname: router.pathname,
            query: { ...router.query, t: previousDate },
          }}
          replace
          title={t('previousDay')}
        >
          <VisuallyHidden.Root>{t('previousDay')}</VisuallyHidden.Root>
          <ChevronLeft className={styles.linkIcon} aria-hidden />
        </Link>
      ) : null}

      <div className={styles.inputWrapper}>
        <button
          aria-controls={inputId}
          aria-expanded={isInputVisible}
          aria-label={t('changeDate')}
          title={t('changeDate')}
          className={styles.button}
          hidden={isInputVisible}
          onClick={() => setInputVisibility(true)}
        >
          {formatEpgDate(currentDate, language)}
        </button>

        <input
          type="date"
          className={styles.input}
          hidden={!isInputVisible}
          id={inputId}
          min={firstDate}
          max={lastDate}
          defaultValue={currentDate}
          onBlur={({ currentTarget: element }) => {
            element.reportValidity();
          }}
          onChange={({ currentTarget: element }) => {
            if (element.value && element.checkValidity()) {
              changeDate(element.value);
            }
          }}
          title={t('changeDate')}
          ref={inputRef}
        />
      </div>

      {currentDate !== lastDate ? (
        <Link
          className={styles.link}
          href={{
            pathname: router.pathname,
            query: { ...router.query, t: nextDate },
          }}
          replace
          title={t('nextDay')}
        >
          <VisuallyHidden.Root>{t('nextDay')}</VisuallyHidden.Root>
          <ChevronRight className={styles.linkIcon} aria-hidden />
        </Link>
      ) : null}
    </div>
  );
};
